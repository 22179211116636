interface SavedItem {
  id: string
  dateSaved: Date
}

export default class SavedItems {
  itemsKey = "savedItems"
  items: Array<SavedItem> = []
  triggerButtonAttr: string
  countPillAttr: string
  countAttr: string
  productPreviewAttr: string
  savedListAttr: string

  constructor(
    triggerButtonAttr: string = "data-product-save",
    countPillAttr: string = "data-product-save-pill",
    countAttr: string = "data-product-save-count",
    productPreviewAttr: string = `[data-shopify="product-preview"]`,
    savedListAttr: string = `data-product-save-list`,
  ) {
    const maybeItems = localStorage.getItem(this.itemsKey)
    if (maybeItems) {
      this.items = JSON.parse(maybeItems)
    }
    this.triggerButtonAttr = triggerButtonAttr
    this.countPillAttr = countPillAttr
    this.countAttr = countAttr
    this.productPreviewAttr = productPreviewAttr
    this.savedListAttr = savedListAttr
    document.addEventListener("DOMContentLoaded", () => {
      this.refreshStatus()
      this.refreshCount()
      this.refreshSavedList()
      this.initEventListeners()
    })
  }

  initEventListeners() {
    const elements = document.querySelectorAll(`[${this.triggerButtonAttr}]`)
    elements.forEach((element) => {
      element.addEventListener("click", (e) => {
        const id = element.getAttribute(`${this.triggerButtonAttr}`)
        if (!id) return
        if (this.items.some((i) => i.id === id)) {
          this.remove(id)
        } else {
          this.add(id)
        }
        this.refreshStatus()
        this.refreshCount()
        this.refreshSavedList()
      })
    })
  }

  refreshStatus() {
    const elements = document.querySelectorAll(`[${this.triggerButtonAttr}]`)
    elements.forEach((element) => {
      const id = element.getAttribute(`${this.triggerButtonAttr}`)
      if (!id) return
      if (this.isSaved(id)) {
        element.classList.add("is-filled")
      } else {
        element.classList.remove("is-filled")
      }
    })
  }

  refreshSavedList() {
    const savedList = document.querySelector(`[${this.savedListAttr}]`)
    if (!savedList) return
    const allProducts = savedList.querySelectorAll(`[${this.triggerButtonAttr}]`)
    allProducts.forEach((product) => {
      const id = product.getAttribute(`${this.triggerButtonAttr}`)
      if (!id) return
      if (!this.isSaved(id)) {
        const element = document
          .querySelector(`[${this.triggerButtonAttr}="${id}"]`)
          ?.closest(this.productPreviewAttr)
        const parent = element?.parentNode
        if (element && parent) {
          parent.removeChild(element)
        }
      }
    })
  }

  refreshCount() {
    const element = document.querySelector(`[${this.countAttr}]`)
    const container = document.querySelector(`[${this.countPillAttr}]`) as HTMLElement
    element!.textContent = this.items.length.toString()
    if (this.items.length === 0) {
      container.style.display = "none"
    } else {
      container.style.display = "flex"
    }
  }

  isSaved(item: string) {
    return this.items.some((i) => i.id === item)
  }

  add(item: string) {
    this.items.push({id: item, dateSaved: new Date()})
    this.save()
  }

  remove(item: string) {
    this.items = this.items.filter((i) => i.id !== item)
    this.save()
  }

  clear() {
    this.items = []
    this.save()
  }

  save() {
    localStorage.setItem(this.itemsKey, JSON.stringify(this.items))
  }
}
