const title = `
<div class="shopify-cart-header" data-element="cart.header">
  <h4 class="shopify-cart-title" data-element="cart.title">Cart</h4>
  <button class="shopify-cart-close {{data.classes.cart.close}}" data-element="cart.close">
    <span class="visuallyhidden">Close cart</span>
    <span class="svg-icon">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.05029 7.05025L16.9498 16.9497" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M16.9497 7.05025L7.05021 16.9497" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>
    </span>
   </button>
</div>`

const lineItems = `
<div class="shopify-cart-content" data-element="cart.cartContent">
  {{#data.isEmpty}}<p class="shopify-cart-empty-text" data-element="cart.empty">Your cart is empty</p>{{/data.isEmpty}}
  <ul role="list" class="shopify-cart-items" data-element="cart.lineItems">{{{data.lineItemsHtml}}}</ul>
</div>`

const footer = `
{{^data.isEmpty}}
  <div class="shopify-cart-footer" data-element="cart.footer">
    <div class="shopify-cart-note-c" data-element="cart.note">
      <label class="shopify-cart-note-label" for="{{data.cartNoteId}}" data-element="cart.noteDescription">Special instructions</label>
      <textarea class="shopify-cart-note-input {{data.classes.cart.noteTextArea}}" id="{{data.cartNoteId}}" data-element="cart.noteTextArea" rows="3"/>{{data.cartNote}}</textarea>
      <p class="shopify-cart-notice" data-element="cart.notice">*Shipping and discount codes are added at checkout.</p>
    </div>
    <div class="shopify-cart-checkout-c">
      <div class="shopify-cart-subtotal-c">
        <p class="shopify-cart-subtotal-text" data-element="cart.total">Subtotal</p>
        <p class="shopify-cart-subtotal" data-element="cart.subtotal">{{data.formattedTotal}}</p>
      </div>
      <button class="shopify-cart-checkout-btn {{data.classes.cart.button}}" type="button" data-element="cart.button">Checkout</button>
      <div class="shopify-cart-tcs-link">
        By making this purchase you agree to our <a href="/page/terms-and-conditions" target="_blank">Terms and Conditions</a>.
      </div>
      <div class="shopify-cart-payment-options">
        <img class="shopify-cart-payment-option" src="https://uploads-ssl.webflow.com/664b4949b2f33d7b21560a68/6654b1f7903e81efea31f081_VISA.png" />
        <img class="shopify-cart-payment-option" src="https://uploads-ssl.webflow.com/664b4949b2f33d7b21560a68/6654b1f6f7c4879079fe0590_Mastercard.png" />
        <img class="shopify-cart-payment-option" src="https://uploads-ssl.webflow.com/664b4949b2f33d7b21560a68/6654b1f6d3bd556f8f682727_Apple%20Pay.png" />
        <img class="shopify-cart-payment-option" src="https://uploads-ssl.webflow.com/664b4949b2f33d7b21560a68/6654b1f673d7ffa8673d9cfe_Revolut.png" />
      </div>
    </div>
  </div>
{{/data.isEmpty}}`

const cartTemplate = {
  title: title,
  lineItems: lineItems,
  footer: footer,
}

export default cartTemplate
