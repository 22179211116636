import actions from "./shopify-templates/actions-template"
import cart from "./shopify-templates/cart-template"
import lineItem from "./shopify-templates/line-item-template"
import price from "./shopify-templates/price-template"
import toggle from "./shopify-templates/toggle-template"

export const actionsOptions = {
  // The product on the page
  iframe: false, // Whether to render the product in an iframe
  templates: actions, // The template to use to render the product
  buttonDestination: "cart", // Where to add the product when adding to cart ('cart', 'modal', 'checkout', 'onlineStore')
  isButton: false, // Whether or not the entire product is a button
  // layout: "vertical", // Whether the product is rendered vertically or horizontally
  // width: "500px", // The width of the product (doesn't seem to be used)
  order: [
    // The order to render the components (see contents for descriptions of components)
    "all",
  ],
  contents: {
    // Which components to render
    // This list is an example that represents the components defined in the product-template. If you add a new item to the list you must also add it to the "order" property
    all: true, // The price and compare at price (original price if discounted)
  },
  classes: {
    // The override classes for the different elements
    options: "product-variants-c",
    buttonWrapper: "product-button-c",
    button: "product-button",
  },
  events: {
    addVariantToCart: function (_product: any) {
      const body = document.querySelector("body")
      body?.classList.add("product-added-to-cart")
    },
  },
}

export const cartOptions = {
  // The cart that takes the user to the checkout page
  iframe: false, // Whether to render the cart in an iframe
  templates: cart, // The template to use to render the cart
  startOpen: false, // Whether the cart should be open on load
  popup: false, // Whether the checkout process opens in a popup or the same window
  order: ["title", "lineItems", "footer"], // The order to render the components (see contents for descriptions of components)
  width: 670,
  contents: {
    // Which components to render
    // This list is an example that represents the components defined in the cart-template. If you add a new item to the list you must also add it to the "order" property
    title: true, // The header which includes the cart title and close button
    lineItems: true, // The products in the cart
    footer: true, // Includes the totals, notes, and checkout button
  },
  classes: {
    // The override classes for the different elements
    cart: "shopify-cart-c",
  },
  text: {
    title: "Cart",
    empty: "Your cart is empty.",
    button: "Checkout",
    total: "Subtotal",
    currency: "EUR",
    notice: "Shipping and discount codes are added at checkout.",
    noteDescription: "Special instructions for seller",
    closeAccessibilityLabel: "Close cart",
  },
}

export const lineItemOptions = {
  // The products listed in the cart
  templates: lineItem,
  order: [
    // The order to render the components (see contents for descriptions of components)
    "all",
  ],
  contents: {
    // Which components to render
    all: true,
  },
}

export const priceOptions = {
  // The product on the page
  iframe: false, // Whether to render the product in an iframe
  templates: price, // The template to use to render the product
  buttonDestination: "cart", // Where to add the product when adding to cart ('cart', 'modal', 'checkout', 'onlineStore')
  isButton: false, // Whether or not the entire product is a button
  // layout: "vertical", // Whether the product is rendered vertically or horizontally
  // width: "500px", // The width of the product (doesn't seem to be used)
  order: [
    // The order to render the components (see contents for descriptions of components)
    "all",
  ],
  contents: {
    // Which components to render
    // This list is an example that represents the components defined in the product-template. If you add a new item to the list you must also add it to the "order" property
    all: true, // The price and compare at price (original price if discounted)
  },
  classes: {
    // The override classes for the different elements
    product: "product-price-c",
  },
  text: {
    // The override text for the different elements
    currency: "EUR",
  },
}

export const toggleOptions = {
  // The cart button that displays the number of items in the cart and toggles the cart to open
  iframe: false,
  templates: toggle, // The template to use to render the toggle
  sticky: false,
  order: [
    // The order to render the components (see contents for descriptions of components)
    "all",
  ],
  contents: {
    // Which components to render
    all: true,
  },
  text: {
    // The override text for the different elements
    title: "Cart",
  },
}
