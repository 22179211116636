const all = `
{{#data.selectedVariant}}
  <div class="product-price-c">
    <div class="pill is-black">
      <span class="pill-small-t">
        {{data.formattedPrice}}
      </span>
    </div>
  </div>
{{/data.selectedVariant}}
`

const productTemplate = {
  all: all,
}

export default productTemplate
