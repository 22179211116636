export type HideElementsT = {
  className?: string
}

export default function initHideElements({className = ".w-condition-invisible"}) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Hide Elements")
  }
  document.querySelectorAll(className).forEach((item) => {
    item.remove()
  })
}
