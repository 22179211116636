const all = `
  <div class="shopify-cart-item">
    <span class="shopify-cart-item-border"></span>
    <div class="shopify-cart-item-details-c">
      <img class="shopify-cart-item-img" src="{{data.lineItemImage}}" data-element="lineItem.image" />
      <div class="shopify-cart-item-details">
        <div class="pill is-filled is-large is-black">
          <span class="pill-large-t" data-element="lineItem.itemTitle">{{data.title}}</span>
        </div>
        <div class="shopify-cart-item-details-second-row">
          {{#data.variantTitle}}
            <div class="pill is-large is-black">
              <span class="pill-large-t" data-element="lineItem.variantTitle">{{data.variantTitle}}</span>
            </div>
          {{/data.variantTitle}}
          <div class="pill is-large is-black">
            <span class="pill-large-t {{data.classes.lineItem.price}}" data-element="lineItem.price">{{data.formattedPrice}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="shopify-cart-item-actions">
      <div class="shopify-cart-item-quantity-c {{data.classes.lineItem.quantity}}" data-element="lineItem.quantity">
        <button class="shopify-cart-item-quantity-btn is-decrement quantity-decrement {{data.classes.lineItem.quantityButton}} {{data.classes.lineItem.quantityDecrement}}" type="button" data-line-item-id="{{data.id}}" data-element="lineItem.quantityDecrement">
          <span class="svg-icon">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12H19" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
          </span>
          <span class="visuallyhidden">Reduce item quantity by one</span>
        </button>
        <div class="shopify-cart-item-quantity">
          <input class="shopify-cart-item-quantity-input {{data.classes.lineItem.quantityInput}}" type="number" min="0" aria-label="Quantity" data-line-item-id="{{data.id}}" value="{{data.quantity}}" data-element="lineItem.quantityInput"/>
        </div>
        <button class="shopify-cart-item-quantity-btn is-increment quantity-increment {{data.classes.lineItem.quantityButton}} {{data.classes.lineItem.quantityIncrement}}" type="button" data-line-item-id="{{data.id}}" data-element="lineItem.quantityIncrement">
          <span class="svg-icon">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12H19" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
              <path d="M12 5L12 19" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <span class="visuallyhidden">Increase item quantity by one</span>
          </span>
        </button>
      </div>
      <div class="shopify-cart-item-action">
        <span class="svg-icon">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 7L5 21" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M19 7L19 21" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M19 21L5 21" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M21 7L3 7" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M16 2L8 2" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M8 7L8 2" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
            <path d="M16 7L16 2" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>
    </div>
  </div>
`

const lineItemTemplate = {
  all: all,
}

export default lineItemTemplate
