const all = `
{{#data.hasVariants}}<div class="product-variants-c" data-element="product.options">{{{data.optionsHtml}}}</div>{{/data.hasVariants}}
<div class="product-button-c" data-element="product.buttonWrapper"><button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="product-button {{#data.buttonDisabled}}is-disabled{{/data.buttonDisabled}}" data-element="product.button">{{data.buttonText}}</button></div>
`

const actionsTemplate = {
  all: all,
}

export default actionsTemplate
