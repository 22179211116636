if (process.env.NODE_ENV === "development") {
  console.log("Running TAIPPE")
}

import ShopifyBuy from "@shopify/buy-button-js"

import initCapsuleEndDate, {CapsuleEndDateT} from "./capsule-end-date"
import initHideElements, {HideElementsT} from "./hide-elements"
import initHideAllEmptyCollectionContainers, {
  HideEmptyCollectionT,
} from "./hide-empty-collection-container"
import initHorizontalScroller, {HorizontalScrollerT} from "./horizontal-scroller"
import initLoader, {LoaderT} from "./loader"
import {
  initCart,
  initProductsPrices,
  initProductActions,
  initProductPrice,
  ProductT,
} from "./shopify-products"
import initSwiper, {SwiperCarouselT} from "./swiper"
import initReverseLetter from "./reverse-letter"
import PageTransitions from "./page-transitions"
import SavedItems from "./saved-items"

var client = ShopifyBuy.buildClient({
  domain: "7e8bfe-6f.myshopify.com",
  storefrontAccessToken: "4c42d4a2ec6bcf40d058ea2a0f8bb4f3", // previously apiKey, now deprecated
})

interface CustomWindow extends Window {
  ui: (args: any) => void
  initCapsuleEndDate: (args: CapsuleEndDateT) => void
  initHideElements: (args: HideElementsT) => void
  initHideAllEmptyCollectionContainers: (args: HideEmptyCollectionT) => void
  initHorizontalScroller: (args: HorizontalScrollerT) => void
  initLoader: (args: LoaderT) => void
  initCart: (ui: ProductT) => void
  initProductsPrices: (ui: ProductT) => void
  initProductActions: (ui: ProductT) => void
  initProductPrice: (ui: ProductT) => void
  initSwiper: (ui: SwiperCarouselT) => void
  initReverseLetter: () => void
  PageTransitions: typeof PageTransitions
  SavedItems: SavedItems
}

declare var window: CustomWindow

window.ui = ShopifyBuy.UI.init(client)

window.initCapsuleEndDate = initCapsuleEndDate
window.initHideElements = initHideElements
window.initHideAllEmptyCollectionContainers = initHideAllEmptyCollectionContainers
window.initHorizontalScroller = initHorizontalScroller
window.initLoader = initLoader
window.initCart = initCart
window.initProductsPrices = initProductsPrices
window.initProductActions = initProductActions
window.initProductPrice = initProductPrice
window.initReverseLetter = initReverseLetter
window.initSwiper = initSwiper
window.PageTransitions = PageTransitions
window.SavedItems = new SavedItems()
