export type HorizontalScrollerT = {
  maxWidth: string
  dataAttrName?: string
  offsetFromRight?: number
  scrollingSpeed?: number
}

export default function initHorizontalScroller({
  dataAttrName = "data-h-scroller",
  maxWidth,
  offsetFromRight = 0,
  scrollingSpeed = 0,
}: HorizontalScrollerT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Horizontal scroller")
  }
  document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll(`[${dataAttrName}='wrapper']`).forEach((wrapper) => {
      const wrapperEl = wrapper as HTMLElement
      const container = wrapper.querySelector(`[${dataAttrName}='container']`) as HTMLElement
      const list = wrapper.querySelector(`[${dataAttrName}='list']`) as HTMLElement
      const trigger = wrapper.querySelector(`[${dataAttrName}='trigger']`) as HTMLElement
      const mediaQuerySm = window.matchMedia(`(max-width: ${maxWidth})`)
      mediaQuerySm.addEventListener("change", setWidths)
      if (!container) {
        console.warn(`Could not find element with selector [${dataAttrName}='container']`)
        return
      }
      if (!list) {
        console.warn(`Could not find element with selector [${dataAttrName}='list']`)
        return
      }
      if (!trigger) {
        console.warn(`Could not find element with selector [${dataAttrName}='trigger']`)
        return
      }
      function setWidths(e: MediaQueryListEvent) {
        const containerWidth = container ? container.clientWidth : 0
        const containerPaddingLeft = window
          .getComputedStyle(container)
          .getPropertyValue("padding-left")
        const listWidth = list ? list.scrollWidth : 0
        trigger.style.height = `calc(100% - 100vh)`
        wrapperEl.style.height = `calc(${listWidth}px + ${scrollingSpeed}vw)`
        if (e.matches) {
          list.style.width = `calc(${listWidth}px - 100vw + ${containerPaddingLeft} + ${offsetFromRight}vw)`
        } else {
          list.style.width = `calc(${listWidth}px - 100vw + ((100vw - ${containerWidth}px) / 2) + ${containerPaddingLeft} + ${offsetFromRight}vw)`
        }
      }
      setWidths({matches: mediaQuerySm.matches} as MediaQueryListEvent)
    })
  })
}
