import {
  actionsOptions,
  cartOptions,
  lineItemOptions,
  priceOptions,
  toggleOptions,
} from "./shopify-options"

export type ProductT = {
  ui: any
  dataAttrName?: string
  containerSelector?: string
  targetSelector?: string
  idSelector?: string
}

function createActions(ui: any, id: string, target: HTMLElement) {
  ui.createComponent("product", {
    id: id,
    node: target,
    moneyFormat: "{{amount}} EUR",
    options: {
      product: actionsOptions,
    },
  })
}

function createCart(ui: any, target: HTMLElement) {
  ui.createComponent("cart", {
    node: target,
    moneyFormat: "{{amount}} EUR",
    toggles: [
      {
        node: document.getElementById("toggle"),
      },
    ],
    options: {
      cart: cartOptions,
      lineItem: lineItemOptions,
      toggle: toggleOptions,
    },
  })
}

function createProduct(ui: any, id: string, target: HTMLElement) {
  ui.createComponent("product", {
    id: id,
    node: target,
    moneyFormat: "{{amount}} EUR",
    options: {
      product: priceOptions,
    },
  })
}

export function initCart({ui, dataAttrName = "data-shopify", targetSelector = "cart"}: ProductT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Shopify cart")
  }

  const target = document.querySelector(`[${dataAttrName}="${targetSelector}"]`) as HTMLElement
  if (!target) {
    console.warn(`Could not find element with selector [${dataAttrName}="${targetSelector}"]`)
    return `Could not find element with selector [${dataAttrName}="${targetSelector}"]`
  }
  createCart(ui, target)
}

export function initProductsPrices({
  ui,
  dataAttrName = "data-shopify",
  containerSelector = "product-preview",
  targetSelector = "price",
  idSelector = "id",
}: ProductT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Shopify products prices")
  }

  document.querySelectorAll(`[${dataAttrName}="${containerSelector}"]`).forEach((product) => {
    const id = product.querySelector(`[${dataAttrName}="${idSelector}"]`)?.innerHTML
    const target = product.querySelector(`[${dataAttrName}="${targetSelector}"]`) as HTMLElement
    if (!id) {
      console.warn(`Could not find element with selector [${dataAttrName}="${idSelector}"]`)
      return `Could not find element with selector [${dataAttrName}="${idSelector}"]`
    }
    if (!target) {
      console.warn(`Could not find element with selector [${dataAttrName}="${targetSelector}"]`)
      return `Could not find element with selector [${dataAttrName}="${targetSelector}"]`
    }
    createProduct(ui, id, target)
  })
}

export function initProductPrice({
  ui,
  dataAttrName = "data-shopify",
  targetSelector = "price",
  idSelector = "id",
}: ProductT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Shopify product price")
  }

  const id = document.querySelector(`[${dataAttrName}="${idSelector}"]`)?.innerHTML
  const target = document.querySelector(`[${dataAttrName}="${targetSelector}"]`) as HTMLElement
  if (!id) {
    console.warn(`Could not find element with selector [${dataAttrName}="${idSelector}"]`)
    return `Could not find element with selector [${dataAttrName}="${idSelector}"]`
  }
  if (!target) {
    console.warn(`Could not find element with selector [${dataAttrName}="${targetSelector}"]`)
    return `Could not find element with selector [${dataAttrName}="${targetSelector}"]`
  }
  createProduct(ui, id, target)
}

export function initProductActions({
  ui,
  dataAttrName = "data-shopify",
  targetSelector = "actions",
  idSelector = "id",
}: ProductT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Shopify product actions")
  }

  const id = document.querySelector(`[${dataAttrName}="${idSelector}"]`)?.innerHTML
  const target = document.querySelector(`[${dataAttrName}="${targetSelector}"]`) as HTMLElement
  if (!id) {
    console.warn(`Could not find element with selector [${dataAttrName}="${idSelector}"]`)
    return `Could not find element with selector [${dataAttrName}="${idSelector}"]`
  }
  if (!target) {
    console.warn(`Could not find element with selector [${dataAttrName}="${targetSelector}"]`)
    return `Could not find element with selector [${dataAttrName}="${targetSelector}"]`
  }
  createActions(ui, id, target)
}
