export type HideEmptyCollectionT = {
  dataAttrName?: string
  containerValue?: string
  collectionValue?: string
  itemValue?: string
  emptyClass?: string
}

export default function initHideAllEmptyCollectionContainers({
  dataAttrName = "data-hide-empty-collections",
  containerValue = "container",
  collectionValue = "collection",
  itemValue = "item",
  emptyClass = "container-empty-collection",
}: HideEmptyCollectionT) {
  const containers = document.querySelectorAll(`[${dataAttrName}="${containerValue}"]`)
  if (!containers || containers.length === 0) {
    console.warn(`Could not find element with selector [${dataAttrName}="${containerValue}"]`)
    return `Could not find element with selector [${dataAttrName}="${containerValue}"]`
  }

  containers.forEach((container) => {
    const containerEl = container as HTMLElement
    const collection = containerEl.querySelector(`[${dataAttrName}="${collectionValue}"]`)
    if (!collection) {
      console.warn(`Could not find collection with selector [${dataAttrName}="${collectionValue}"]`)
      return
    }
    const collectionItems = collection.querySelectorAll(`[${dataAttrName}="${itemValue}"]`)
    if (collectionItems.length === 0) {
      containerEl.classList.add(emptyClass)
      containerEl.style.display = "none"
    }
  })
}
