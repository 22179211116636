export type CapsuleEndDateT = {
  dataAttrName?: string
  itemSelector?: string
  priceSelector?: string
  endDateSelector?: string
}

export default function initCapsuleEndDate({
  dataAttrName = "data-shopify",
  itemSelector = "product-preview",
  priceSelector = "price",
  actionsSelector = "actions",
  endDateSelector = "data-capsule-end-date",
}) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Reverse letter")
  }
  const items = Array.from(
    document.querySelectorAll(`[${dataAttrName}="${itemSelector}"]`),
  ) as HTMLElement[]

  if (!items || items.length === 0) {
    console.warn(`Could not find element with selector [${dataAttrName}]`)
    return `Could not find element with selector [${dataAttrName}]`
  }

  items.map((item) => {
    const endDateEl = item.querySelector(`[${endDateSelector}]`) as HTMLElement
    const priceEl = item.querySelector(`[${dataAttrName}="${priceSelector}"]`) as HTMLElement
    const actionsEl = item.querySelector(`[${dataAttrName}="${actionsSelector}"]`) as HTMLElement

    if (!endDateEl) {
      console.warn(`Could not find element with selector [${endDateSelector}]`)
      return `Could not find element with selector [${endDateSelector}]`
    }

    if (!priceEl) {
      console.warn(`Could not find element with selector [${dataAttrName}="${priceSelector}"]`)
      return `Could not find element with selector [${dataAttrName}="${priceSelector}"]`
    }

    const endDate = new Date(endDateEl.dataset.capsuleEndDate || 0)
    const today = new Date()
    if (endDate.getTime() < today.getTime()) {
      priceEl.style.opacity = "0.2"
    }

    if (endDate.getTime() < today.getTime() && actionsEl) {
      actionsEl.style.display = "none"
    }
  })
}
