export default function initReverseLetter() {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Reverse letter")
  }
  document.querySelectorAll("span").forEach((span) => {
    if (span.innerText.toLowerCase() === "p") {
      span.style.display = "inline-block"
      span.style.transform = "scaleX(-1) translateX(-0.05em)"
    }
  })
}
