const all = `
    <div class="navigation-item">
      <h5 class="visuallyhidden" data-element="toggle.title">{{data.text.title}}</h5>
      <div class="pill-svg">
        <div class="svg-icon">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.422607" y1="1.24163" x2="5.56787" y2="1.24163" stroke="currentColor" stroke-width="2px" />
            <line x1="6.75415" y1="6.70454" x2="23.21" y2="6.70454" stroke="currentColor" stroke-width="2px" />
            <line x1="8.61694" y1="14.8989" x2="20.9775" y2="14.8989" stroke="currentColor" stroke-width="2px" />
            <line x1="4.99869" y1="1.59796" x2="9.09588" y2="15.2552" stroke="currentColor" stroke-width="2px" />
            <line x1="22.7415" y1="7.02402" x2="20.4988" y2="15.2629" stroke="currentColor" stroke-width="2px" />
            <rect x="8.74878" y="17.059" width="3.67649" height="3.67649" transform="rotate(45 8.74878 17.059)" fill="currentColor"/>
            <rect x="20.9775" y="17.059" width="3.67649" height="3.67649" transform="rotate(45 20.9775 17.059)" fill="currentColor"/>
          </svg>
        </div>
      </div>
      <div class="navigation-small-t" data-element="toggle.count">{{data.count}}</div>
    </div>
`

const toggleTemplate = {
  all: all,
}

export default toggleTemplate
