export type LoaderT = {
  dataAttrName?: string
  containerSelector?: string
  barSelector?: string
  startSelector?: string
  endSelector?: string
  videoSelector?: string
}

export default function initLoader({
  dataAttrName = "data-loader",
  containerSelector = "container",
  barSelector = "bar",
  startSelector = "start-value",
  endSelector = "end-value",
  videoSelector = "video",
}: LoaderT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Loader")
  }
  const containers = Array.from(
    document.querySelectorAll(`[${dataAttrName}="${containerSelector}"]`),
  ) as HTMLElement[]

  if (!containers || containers.length === 0) {
    console.warn(`Could not find element with selector [${dataAttrName}="${containerSelector}"]`)
    return `Could not find element with selector [${dataAttrName}="${containerSelector}"]`
  }

  containers.map((bar) => {
    const barEl = bar.querySelector(`[${dataAttrName}="${barSelector}"]`) as HTMLElement
    const startEl = bar.querySelector(`[${dataAttrName}="${startSelector}"]`) as HTMLElement
    const endEl = bar.querySelector(`[${dataAttrName}="${endSelector}"]`) as HTMLElement
    const body = document.querySelector("body")

    if (!barEl) {
      console.warn(`Could not find element with selector [${dataAttrName}="${barSelector}"]`)
    } else if (!startEl) {
      console.warn(`Could not find element with selector [${dataAttrName}="${startSelector}"]`)
    } else if (!endEl) {
      console.warn(`Could not find element with selector [${dataAttrName}="${endSelector}"]`)
    }

    // Add class to body if bar isn't found; meaning that it is filtered out by Webflow collection
    if (!barEl) {
      body?.classList.add("capsule-is-active")
      return null
    }

    const startValueRaw = startEl.textContent || 0
    const endValueRaw = endEl.textContent || 0
    const currentValue = new Date()
    const startValue = new Date(startValueRaw)
    const endValue = new Date(endValueRaw)

    // Calculate the range and the percentage
    let percentage = 0
    const dateRange = (endValue as Date).getTime() - (startValue as Date).getTime()
    percentage =
      (((currentValue as Date).getTime() - (startValue as Date).getTime()) / dateRange) * 100

    // Ensure percentage is between 0 and 100
    percentage = Math.round(Math.max(0, Math.min(100, percentage)))

    // Update the bar with the calculated percentage
    if (barEl) {
      barEl.setAttribute(
        "style",
        `clip-path: inset(0 0 0 ${percentage}%); -webkit-clip-path: inset(0 0 0 ${percentage}%);`,
      )
    }

    // Add class ot body if percentage is 100; meaning that it launches today
    if (percentage >= 100 && containers.length === 1) {
      body?.classList.add("launch-is-today")
    }

    // Remove video from DOM if percentage is less than 100; meaning that the drop is still loading and video isn't needed
    if (percentage < 100) {
      const video = document.querySelector(`[${dataAttrName}="${videoSelector}"]`) as HTMLElement
      if (!video) {
        console.warn(`Could not find element with selector [${dataAttrName}="${videoSelector}"]`)
      } else {
        video.remove()
      }
    }
  })
}
