import Swiper from "swiper"
import {Navigation, Pagination} from "swiper/modules"

export type SwiperCarouselT = {
  dataAttrName: string
  swiperEl: string
  autoHeight: boolean
  autoplay: boolean
  centeredSlides: boolean
  direction: "horizontal" | "vertical"
  enabled: boolean
  initialSlide: number
  loop: boolean
  slidesOffsetBefore: number
  slidesOffsetAfter: number
  slidesPerView: number | "auto"
  spaceBetween: number
  speed: number
  rewind: boolean
  navPrev: string
  navNext: string
}

export default function initSwiper({
  dataAttrName = "data-swiper",
  swiperEl = "swiper",
  autoHeight = false,
  autoplay = false,
  centeredSlides = true,
  direction = "horizontal",
  enabled = true,
  initialSlide = 0,
  loop = true,
  slidesOffsetBefore = 0,
  slidesOffsetAfter = 0,
  slidesPerView = "auto",
  spaceBetween = 4,
  speed = 300,
  rewind = false,
  navPrev = "button-prev",
  navNext = "button-next",
}: SwiperCarouselT) {
  if (process.env.NODE_ENV === "development") {
    console.log("Running Swiper")
  }
  new Swiper(`[${dataAttrName}="${swiperEl}"]`, {
    modules: [Navigation, Pagination],
    autoHeight: autoHeight,
    autoplay: autoplay,
    centeredSlides: centeredSlides,
    direction: direction,
    enabled: enabled,
    initialSlide: initialSlide,
    loop: loop,
    slidesOffsetBefore: slidesOffsetBefore,
    slidesOffsetAfter: slidesOffsetAfter,
    slidesPerView: slidesPerView,
    spaceBetween: spaceBetween,
    speed: speed,
    rewind: rewind,
    navigation: {
      prevEl: `[${dataAttrName}="${navPrev}"]`,
      nextEl: `[${dataAttrName}="${navNext}"]`,
    },
  })
}
